import React, { Fragment } from "react";
import { MASS_UNITS, VOLUME_UNITS } from "../../constants";

const ProductsForm = ({
  data,
  ingredientAmounts,
  handleSelectChange,
  allItems,
  selectedUnits,
  conversionRates,
  setIngredientAmounts,
  handleUnitChange,
  handleRemoveItem,
  handleAddIngredient,
  productUnits,
  handleProductUnitChange,
  handleRemoveUnit,
  handleAddUnit,
  doughs,
  ingredients,
  handleAddIngredientForProducts
}) => (

  <div>
    PRODUCTS FORM
    <div>
      {console.log("ingredientAmounts ", ingredientAmounts)}
      {/* {console.log("selectedUnits ", selectedUnits)}
      {console.log("conversionRates ", conversionRates)}
      {console.log("doughs ", doughs)} */}
      {/* {Array.isArray(ingredientAmounts) ? 
        <>
          <h3>Ingredients:</h3>
          {Array.isArray(ingredientAmounts) ? (
            <>
              {ingredientAmounts.map((ingredientAmount, index) => (
                <div key={index}>
                  <select
                    data-key="ingredients"
                    onChange={(e) =>
                      handleSelectChange(
                        e,
                        index,
                        ingredientAmounts,
                        allItems.find(
                          (ingredient) =>
                            ingredient.id === e.target.value
                        )
                      )
                    }
                    value={ingredientAmount.id}
                  >
                    <option value="">Select Ingredient</option>
                    {allItems.map((ingredient, index) => (
                      <option key={index} value={ingredient.id}>
                        {ingredient.name} ({ingredient.collection})
                      </option>
                    ))}
                  </select>

                  {ingredientAmount.id !== "" ? (
                    <>
                      <input
                        type="number"
                        value={
                          selectedUnits[index].isVolume
                            ? ingredientAmount.amount /
                            selectedUnits[index].unit.amount /
                            conversionRates[index]
                            : ingredientAmount.amount /
                            selectedUnits[index].unit.amount
                        }
                        onChange={(e) => {
                          const newIngredientAmounts = [
                            ...ingredientAmounts,
                          ];
                          if (selectedUnits[index].isVolume) {
                            newIngredientAmounts[index].amount =
                              e.target.value *
                              selectedUnits[index].unit.amount *
                              conversionRates[index];
                          } else {
                            newIngredientAmounts[index].amount =
                              e.target.value *
                              selectedUnits[index].unit.amount;
                          }

                          setIngredientAmounts(newIngredientAmounts);
                        }}
                        placeholder="Amount"
                      />{" "}
                      {allItems.find(
                        (item) => item.id === ingredientAmount.id
                      ).volume ? (
                        <select
                          onChange={(e) => handleUnitChange(e, index)}
                        >
                          {VOLUME_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select
                          onChange={(e) => handleUnitChange(e, index)}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      )}{" "}
                    </>
                  ) : (
                    <>{"  "}</>
                  )}
                  <button
                    type="button"
                    onClick={() => handleRemoveItem(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" onClick={handleAddIngredient}>
                Add Ingredient
              </button>
            </>
          ) : null}
        </>
       : null} */}

      {console.log("ingredientAmounts ", ingredientAmounts)}

      {Array.isArray(ingredientAmounts) ?
        <>
          <h3>Doughs:</h3>
          {Array.isArray(ingredientAmounts) ? (
            <>
              {ingredientAmounts
                .map((ingredientAmount, index) => ({
                  ingredientAmount,
                  index
                }))
                //   .filter(({ ingredientMap }) =>
                //     ingredientMap.(({ id }) => id.startsWith("doughs"))
                // )

                .map((ingredientAmount, index) => (
                  <div key={index}>
                    {console.log(ingredientAmounts[index], " ingredient")}
                    {ingredientAmounts[index].collection === "doughs" ? <>
                      <select
                        data-key="ingredients"
                        onChange={(e) =>
                          handleSelectChange(
                            e,
                            index,
                            ingredientAmounts,
                            allItems.find(
                              (ingredient) =>
                                ingredient.id === e.target.value
                            )
                          )
                        }
                        value={ingredientAmount.id}
                      >
                        {
                          // ingredientAmount.startsWith("doughs/") 
                          // ingredientAmounts[index].id !== "" 
                          // ingredientAmount[index].id(0,7) === "doughs/" 
                          ingredientAmounts[index].selected ? null :
                            <option key={"Select" + index} value={"doughSelect" + index}>
                              Select Dough
                            </option>
                        }

                        {doughs.map((ingredient, index) => (
                          <option key={index} value={ingredient.id}>
                            {ingredient.name} ({ingredient.collection})
                          </option>
                        ))}
                      </select>
                      {/* {console.log("ingredientAmount ", ingredientAmount)} */}
                      {ingredientAmounts[index].selected ? (
                        <>
                          <input
                            type="number"
                            value={
                              ingredientAmounts[index].amount /
                              selectedUnits[index].unit.amount
                            }
                            onChange={(e) => {
                              const newIngredientAmounts = [
                                ...ingredientAmounts,
                              ];

                              newIngredientAmounts[index].amount =
                                e.target.value *
                                selectedUnits[index].unit.amount;


                              setIngredientAmounts(newIngredientAmounts);
                            }}
                            placeholder="Amount"
                          />{" "}

                          <select
                            onChange={(e) => handleUnitChange(e, index)}
                          >
                            {MASS_UNITS.map((unit, index) => (
                              <option
                                key={index}
                                value={unit.abbreviation}
                              >
                                {unit.abbreviation}
                              </option>
                            ))}
                          </select>{" "}
                        </>
                      ) : (
                        <>{"  "}</>
                      )}
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(index)}
                      >
                        Remove
                      </button>
                    </> : null}
                  </div>
                ))}
              <button type="button" onClick={() => handleAddIngredientForProducts("doughs")}>
                Add Dough
              </button>
            </>
          ) : null}
        </>
        : null}

      {Array.isArray(ingredientAmounts) ?
        <>
          <h3>Ingredients:</h3>
          {Array.isArray(ingredientAmounts) ? (
            <>
              {ingredientAmounts.map((ingredientAmount, index) => (
                <div key={index}>
                  {ingredientAmount.collection === "ingredients" ? <>
                    <div key={index}>
                      <select
                        data-key="ingredients"
                        onChange={(e) =>
                          handleSelectChange(
                            e,
                            index,
                            ingredientAmounts,
                            allItems.find(
                              (ingredient) =>
                                ingredient.id === e.target.value
                            )
                          )
                        }
                        value={ingredientAmount.id}
                      >
                        {
                          // ingredientAmount.startsWith("doughs/") 
                          // ingredientAmounts[index].id !== "" 
                          // ingredientAmount[index].id(0,7) === "doughs/" 
                          ingredientAmounts[index].selected ? null :
                            <option key={"Select" + index} value={"doughSelect" + index}>
                              Select Ingredient
                            </option>
                        }

                        {/* <option value="">Select Ingredient</option> */}
                        {ingredients.map((ingredient, index) => (
                          <option key={index} value={ingredient.id}>
                            {ingredient.name} ({ingredient.collection})
                          </option>
                        ))}
                      </select>




                      {ingredientAmounts[index].selected ? (
                        <>
                          <input
                            type="number"
                            value={
                              ingredientAmounts[index].amount /
                              selectedUnits[index].unit.amount
                            }
                            onChange={(e) => {
                              const newIngredientAmounts = [
                                ...ingredientAmounts,
                              ];

                              newIngredientAmounts[index].amount =
                                e.target.value *
                                selectedUnits[index].unit.amount;


                              setIngredientAmounts(newIngredientAmounts);
                            }}
                            placeholder="Amount"
                          />{" "}

                          {/* <select
                            onChange={(e) => handleUnitChange(e, index)}
                          >
                            {MASS_UNITS.map((unit, index) => (
                              <option
                                key={index}
                                value={unit.abbreviation}
                              >
                                {unit.abbreviation}
                              </option>
                            ))}
                          </select>{" "} */}
                          {allItems.find(
                            (item) => item.id === ingredientAmount.id
                          ).volume ? (
                            <select
                              onChange={(e) => handleUnitChange(e, index)}
                            >
                              {VOLUME_UNITS.map((unit, index) => (
                                <option
                                  key={index}
                                  value={unit.abbreviation}
                                >
                                  {unit.abbreviation}
                                </option>
                              ))}
                              {MASS_UNITS.map((unit, index) => (
                                <option
                                  key={index}
                                  value={unit.abbreviation}
                                >
                                  {unit.abbreviation}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <select
                              onChange={(e) => handleUnitChange(e, index)}
                            >
                              {MASS_UNITS.map((unit, index) => (
                                <option
                                  key={index}
                                  value={unit.abbreviation}
                                >
                                  {unit.abbreviation}
                                </option>
                              ))}
                            </select>
                          )}{" "}
                        </>
                      ) : (
                        <>{"  "}</>
                      )}








                      {/* {ingredientAmount.id !== "" ? (
                    <>
                      <input
                        type="number"
                        value={
                          selectedUnits[index].isVolume
                            ? ingredientAmount.amount /
                            selectedUnits[index].unit.amount /
                            conversionRates[index]
                            : ingredientAmount.amount /
                            selectedUnits[index].unit.amount
                        }
                        onChange={(e) => {
                          const newIngredientAmounts = [
                            ...ingredientAmounts,
                          ];
                          if (selectedUnits[index].isVolume) {
                            newIngredientAmounts[index].amount =
                              e.target.value *
                              selectedUnits[index].unit.amount *
                              conversionRates[index];
                          } else {
                            newIngredientAmounts[index].amount =
                              e.target.value *
                              selectedUnits[index].unit.amount;
                          }

                          setIngredientAmounts(newIngredientAmounts);
                        }}
                        placeholder="Amount"
                      />{" "}
                      {allItems.find(
                        (item) => item.id === ingredientAmount.id
                      ).volume ? (
                        <select
                          onChange={(e) => handleUnitChange(e, index)}
                        >
                          {VOLUME_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select
                          onChange={(e) => handleUnitChange(e, index)}
                        >
                          {MASS_UNITS.map((unit, index) => (
                            <option
                              key={index}
                              value={unit.abbreviation}
                            >
                              {unit.abbreviation}
                            </option>
                          ))}
                        </select>
                      )}{" "}
                    </>
                  ) : (
                    <>{"  "}</>
                  )} */}
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </> : null}
                </div>
              ))
              }
              <button type="button" onClick={() => handleAddIngredientForProducts("ingredients")}>
                Add Ingredient
              </button>
            </>
          ) : null}
        </>
        : null}




    </div>
    <div>
      <h3>Packing Units:</h3>
      {productUnits.map((unit, index) => (
        <div key={index}>
          <input
            type="text"
            value={unit.name}
            placeholder="Unit Name"
            onChange={(e) => handleProductUnitChange(e, index, "name")}
          />
          <input
            type="number"
            value={unit.pieceCount}
            placeholder="Piece Count"
            onChange={(e) => handleProductUnitChange(e, index, "pieceCount")}
          />
          <button type="button" onClick={() => handleRemoveUnit(index)}>
            Remove
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddUnit}>
        Add Unit
      </button>
    </div>
    end of form
  </div>
);

export default ProductsForm;
